/* .App {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-image: url(http://bit.ly/2gPLxZ4);
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
} */

.App {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.Internet-Icon {
  height: 15vmin;
  pointer-events: none;
  margin-bottom: 1em;
}

.App-header {
  align-self: center;
  color: white;
  max-width: 50%;
}

.Content {
  font-size: 1em;
}

@media screen and (min-width: 600px) {
  .Content {
    font-size: calc(10px + 2vmin);
  }
}


.App-Footer {
  font-size: 10pt;
  color: white;
  text-transform: uppercase;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-right: 0.8em;
  margin-bottom: 0.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.App-Footer a {
  color: white;
  font-weight: bold;
}

.App-link {
  color: #fff;
}

.code-ipv6 {
  position: relative;
  justify-content: center;
  display: flex;
}

.isp-info {
  padding-top: 2em;
}

.isp-info p {
  color: white;
}

code:hover {
  font-weight: bold;
  cursor: pointer;
}
